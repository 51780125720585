<template>
  <div class="auxiliary template-1">
    <header class="page-header container">
      <h1>{{ translations.tcAuxiliary }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <!-- / Page Header -->
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome"
        :i18n="translations.components" />
      <!-- / Camp Selector -->
      <div class="page-body container mx-med">
        <section class="section-1">
          <h4>{{ translations.tcUpcomingAuxiliaryOpportunities }}</h4>
          <div class="row" v-if="translations.components">
            <div class="col col-6 nonew" v-if="upcoming_placements.length == 0">
              {{ noUpcomingPlacementsMessage }}
            </div>
            <div class="col col-6" v-else>
              <DateCard v-for="(upcoming_placement, index) in preppedUpcomingPlacements" :i18n="translations.common"
                :key="index" :obj="upcoming_placement.date">
                <BasicDateCard :i18n="translations.components" :obj="{
        title: upcoming_placement.title,
        description: upcoming_placement.description,
        schedule: upcoming_placement.schedule,
        mtg_key: upcoming_placement.mtg_key,
        loc_key: upcoming_placement.loc_key,
        destination: upcoming_placement.destination,
      }" />
              </DateCard>
              <div>
                <router-link to="/programs/sd/auxiliary-placement-dates" class="btn btn-primary btn-w-med">
                  <div>{{ translations.tcViewMore }}</div>
                </router-link>
              </div>
            </div>
            <div class="col col-6">
              <NumberCard v-for="(stat, index) in locationPlacementData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </div>
          </div>
        </section>
        <!-- / Upcoming Opportunities -->
        <section class="stats section-1">
          <div class="container" v-if="statsAreGood">
            <div class="row">
              <div class="col" v-for="(stat, index) in stats" :key="index">
                <app-graph :stat="stat"></app-graph>
              </div>
              <b-button variant="primary" to="/officers/camp/camp-dashboard/#cdash" class="more">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
        </section>
        <!-- / .stats -->
        <section class="section-2">
          <h2>{{ translations.tcNewAuxiliaryMembers }}</h2>
          <div class="row">
            <div class="t-container col col-6 col-100-sd">
              <div class="p-2 bg-block">
                <div class="nonew" v-if="recent_members.length == 0">
                  {{ noNewMembersMessage }}
                </div>
                <div v-else>
                  <b-table class="g-table" :fields="memberFields" :items="recent_members" :per-page="perPage"
                    :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small>
                    <template v-slot:head(member_name)="data">
                      {{ translations.tcMemberNameHeader }}
                    </template>
                    <template v-slot:head(join_date)="data">
                      {{ translations.tcJoinDateHeader }}
                    </template>
                    <template v-slot:cell(sort_name)="data">
                      <a @click.prevent="individualMemberStoreKeyAndRedirect(data.item)" href="#">
                        {{ data.item.name }}
                      </a>
                    </template>
                    <template v-slot:cell(join_date)="data">{{
        !!data.item.join_date ? data.item.join_date.substring(0, 10) : null
      }}</template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div>
              <router-link to="/programs/mb/member-list/naux" class="btn btn-primary btn-w-med mt-3 mb-3 mr-3">
                <div>{{ translations.tcViewMore }}</div>
              </router-link>
              <button class="btn btn-secondary btn-w-med mr-4 mt-3 mb-3" @click="handleSponsorAuxiliaryCandidate"
                v-if="userLogin.membertype.toLowerCase() === 'usa'">
                Sponsor Auxiliary Candidate
              </button>
            </div>
            <div> <b-button :href="auxiliaryMembershipApplication" download target="_blank"
                class="btn btn-secondary btn-w-med mr-4 mt-3 mb-3" v-if="userLogin.membertype.toLowerCase() === 'usa'">
                {{ translations.tcDownloadAuxiliaryApplicationForm }}
              </b-button>
            </div>
          </div>
        </section>
        <!-- / .New Auxiliary Members -->
        <section class="section-3">
          <div class="row">
            <InfoCard v-for="(info, index) in auxiliaryInfo" :key="index" :obj="info"
              @set_program="handleSetProgram($event)" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import DateCard from '@/components/date-card/DateCard.vue'
import Graph from '@/components/Graph.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'auxiliary',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      noUpcomingPlacementsMessage: 'No upcoming placements were found.',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      sortBy: 'Name',
      locationPlacementData: [
        {
          num: 0,
          title: 'AUXILIARY PLACEMENT LOCATIONS SERVICED - FISCAL YEAR',
        },
        {
          num: 0,
          title: 'GIDEONCARD PRESENTATIONS THIS FISCAL YEAR',
        },
      ],
      noNewMembersMessage: 'No new members were found.',
      memberFields: [
        { key: 'sort_name', sortable: true },
        { key: 'join_date', sortable: true },
      ],
      auxiliaryInfo: [
        {
          class: 'col col-6',
          title: 'Auxiliary Member List',
          content: 'View a list of all Auxiliary members in your camp.',
          link: '/programs/mb/member-list/aux',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'Auxiliary Member List',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Auxiliary Camp Officers',
          content: 'View a list of the Auxiliary officers for your camp.',
          link: '/programs/mb/member-list/acof',
          label: 'View More',
          img: {
            src: 'svgs/AuxiliaryIcon-02.svg',
            alt: 'Auxiliary Camp Officers',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Auxiliary Placement Location List',
          content: 'View a list of Scripture placement locations in your camp.',
          link: '/programs/sd/auxiliary-placement-locations',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'Auxiliary Placement Location List',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Auxiliary Candidates',
          content: 'View a list of all Auxiliary candidates in your camp.',
          link: '/auxiliary/auxiliarycandidates/menu',
          label: 'View More',
          img: {
            src: 'svgs/AuxiliaryIcon-02.svg',
            alt: 'Auxiliary Candidates',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Prayer Center',
          content: 'View a list of all prayer requests.',
          link: '/programs/pw/prayer-center-daily/aux',
          label: 'View More',
          img: {
            src: 'svgs/hands-praying.svg',
            alt: 'Prayer Center',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Program Resources',
          content: 'View manuals, videos, and other resources for Auxiliary.',
          emitlink: '/resources/media-portal/program',
          itemId: '23EEC044-33F1-4EDD-9FFD-45F8514EF454', // The sitecore ItemId for Auxiliary Program
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'Program Resources',
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: 'Validate Membership Applications',
          content: 'Review offline candidate applications',
          emitlink: '/auxiliary/camp/validate-membership-applications',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Validate Membership Applications
            width: 64,
            height: 64,
          },
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      getAuxiliaryMembers: 'auxiliary/getAuxiliaryMembers',
      getAuxiliaryPresentations: 'auxiliary/getAuxiliaryPresentations',
      loadAuxiliaryPlacementDatesByCampLast12: 'auxiliary/loadAuxiliaryPlacementDatesByCampLast12',
      setAuxiliaryPageWidgetData: 'auxiliary/setAuxiliaryPageWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey'
    }),
    async page_load() {
      try {
        await this.getAuxiliaryMembers()
        let result = [false]
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getViewTranslations(),
          await this.getComponentTranslations(
            'basic-date-card',
            'camp-select',
            'date-card',
            'number-card',
            'security-banner'
          ),
          await this.getComponentTranslations(
            'common.attire',
            'common.church-service-type',
            'common.days',
            'common.months-abbreviations'
          ),
          (result = await this.setAuxiliaryPageWidgetData({
            ind_key: this.userId,
            org_key: this.user_camp_key,
          })),
          await this.loadAuxiliaryPlacementDatesByCampLast12(3),
          await this.getAuxiliaryPresentations(),
          (this.locationPlacementData[0].num = this.preppedRecentPlacements.length),
          (this.locationPlacementData[1].num = this.presentations.restockingCount),
        ]).then((results) => {
          this.stripReadableText(results[2])
          const translatedText = {
            ...results[1],
            common: { ...this.translations.common },
          }
          this.$set(this.translations, 'components', translatedText)
          this.loadTranslations()
          // this.setLoadingStatus(false)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    handleSponsorAuxiliaryCandidate() {
      this.$router.push({ name: 'sponsorauxiliarycandidate' })
    },
    individualMemberStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data.ind_key)
      this.$router.push('/programs/mb/member-profile')
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.emitlink)
    },
    loadTranslations() {
      this.noUpcomingPlacementsMessage = this.translations.tcNoUpcomingPlacementsWereFound
      this.locationPlacementData[0].title = this.translations.tcAuxiliaryPlacementLocationsServicedFiscalYear
      this.locationPlacementData[1].title = this.translations.tcGideonCardPresentationsThisFiscalYear
      this.noNewMembersMessage = this.translations.tcNoNewMembersWereFound

      this.auxiliaryInfo[0].title = this.translations.tcAuxiliaryMemberList
      this.auxiliaryInfo[0].label = this.translations.tcViewMore
      this.auxiliaryInfo[0].content = this.translations.tcAuxiliaryMemberListDescription
      this.auxiliaryInfo[0].img.alt = this.translations.tcAuxiliaryMemberList

      this.auxiliaryInfo[1].title = this.translations.tcAuxiliaryCampOfficers
      this.auxiliaryInfo[1].label = this.translations.tcViewMore
      this.auxiliaryInfo[1].content = this.translations.tcAuxiliaryCampOfficersDescription
      this.auxiliaryInfo[1].img.alt = this.translations.tcAuxiliaryCampOfficers

      this.auxiliaryInfo[2].title = this.translations.tcAuxiliaryPlacementLocationList
      this.auxiliaryInfo[2].label = this.translations.tcViewMore
      this.auxiliaryInfo[2].content = this.translations.tcAuxiliaryPlacementLocationListDescription
      this.auxiliaryInfo[2].img.alt = this.translations.tcAuxiliaryPlacementLocationList

      this.auxiliaryInfo[3].title = this.translations.tcAuxiliaryCandidates
      this.auxiliaryInfo[3].label = this.translations.tcViewMore
      this.auxiliaryInfo[3].content = this.translations.tcAuxiliaryCandidatesDescription
      this.auxiliaryInfo[3].img.alt = this.translations.tcAuxiliaryCandidates

      this.auxiliaryInfo[4].title = this.translations.tcPrayerCenter
      this.auxiliaryInfo[4].label = this.translations.tcViewMore
      this.auxiliaryInfo[4].content = this.translations.tcPrayerCenterDescription
      this.auxiliaryInfo[4].img.alt = this.translations.tcPrayerCenter

      this.auxiliaryInfo[5].title = this.translations.tcProgramResources
      this.auxiliaryInfo[5].label = this.translations.tcViewMore
      this.auxiliaryInfo[5].content = this.translations.tcProgramResourcesDescription
      this.auxiliaryInfo[5].img.alt = this.translations.tcProgramResourcesCenter

      this.auxiliaryInfo[6].title = 'Validate Membership Applications'
      this.auxiliaryInfo[6].label = this.translations.tcViewMore
      this.auxiliaryInfo[6].content = 'Review offline candidate applications'
      this.auxiliaryInfo[6].img.alt = this.translations.tcProgramResourcesCenter
    },
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      presentations: 'auxiliary/presentations',
      recent_members: 'auxiliary/recent_members',
      recent_placements: 'auxiliary/recent_placements',
      stats: 'auxiliary/auxiliaryStats',
      upcoming_placements: 'auxiliary/upcoming_placements',
      user_camp_key: 'user/userCampKey',
      userId: 'user/userId',
    }),
    allowPage() {
      // return this.isInMenu(this.$route.path)
      return true
    },
    statsAreGood() {
      return this.stats[0].data.current > -1 && this.stats[1].data.current > -1 && this.stats[2].data.current > -1
    },
    preppedRecentPlacements() {
      let placements = []
      this.recent_placements.map((plac, idx) => {
        placements.push(plac.facility_name)
      })
      placements = [...new Set(placements)]
      return placements
    },
    preppedUpcomingPlacements() {
      let placements = []
      if (!this.upcoming_placements) {
        return [
          {
            date: { month: '', day: '', year: '' },
            title: 'loading',
            description: '',
            schedule: '',
          },
        ]
      } else {
        const start = this.translations.tcStarts || 'Starts'
        placements = this.upcoming_placements.map((plac) => {
          const click_destination = !!plac.plf_org_key && constantData.placement_meeting_types.includes(plac.mtg_mtt_key)
            ? '/programs/sd/placement-details' : '/calendar/camp-calendar/event-detail'
          return {
            date: {
              month: plac.month,
              day: plac.day,
              year: plac.year,
            },
            title: plac.facility_name,
            description: plac.coordinator_name,
            schedule: plac.start_time ? `${start} ${plac.start_time}` : '',
            mtg_key: plac.mtg_key,
            loc_key: plac.plf_org_key,
            destination: click_destination
          }
        })
      }
      return placements.slice(0, 3)
    },
    auxiliaryMembershipApplication() {
      return constantData.document_prefix + constantData.auxiliary_membership_application
    },
  },
  created() {
    this.perPage = constantData.per_page_default
  },
  components: {
    appGraph: Graph,
    BasicDateCard: BasicDateCard,
    CampSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.auxiliary {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .section-0 {
    margin-bottom: 60px;

    .quote-verse p {
      font-family: $lora;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 1px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .number-card .content .w-subtitle p:not(.subtitle) {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }

  .section-1 {
    .d-flex.button-bar {
      align-items: center;

      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }

    .number-card .wo-subtitle p {
      font-size: 26px;
      line-height: 32px;
    }

    .sub-text {
      font-family: $DIN;
      text-transform: uppercase;
      font-size: 20px;

      @include breakpoint(sm) {
        display: block;
        margin-left: 0 !important;
        margin-top: 30px;
      }
    }
  }

  .section-2 {
    .sub-support {
      .num {
        display: flex;
        align-items: center;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    .number-card {
      align-items: center;
    }

    .row {
      justify-content: center;
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
